<template>
  <div id="main">
    <router-view/>
  </div>
</template>

<script>
import MessageService from "./services/message-service";

export default {
  name: 'AppRoot',
  data() {
    var messages = [];
    var mounted = false;
    var serviceWorkerRegistation = null;
    return { messages, mounted,serviceWorkerRegistation }
  },
  
  methods: {
    loadMessages: function () {
      MessageService.getMyMessages().then(
        (response) => {
          // var d = typeof response;
          console.log("Polling... mounted=" + this.mounted + " - response.data: " + response.data );
          this.status = response.status;
          var localChecksum = localStorage.getItem('checksum');
          var remoteChecksum = response.checksum;
          console.log("this.serviceWorkerRegistation: " + this.serviceWorkerRegistation);
          if(localChecksum != remoteChecksum && response.data != null) {
            localStorage.setItem('checksum', remoteChecksum);
            console.log("Fresh content!!!!!! do stuff (reload and notify user) ");
            console.log("new data: " + JSON.stringify(response.data));

            var notificationTitle = "Attentie!";
            var notificationBody = "U heeft notificaties open staan in TripTrader";
            if(response.data.opentrip > 0) {
              notificationBody = "U heeft een lopende rit op TripTrader";
            }
            var par = this;
            const options = {
                body: notificationBody,
                icon: require('./assets/triptrader-logo.png'),
                vibrate: [300,100,400,100,400,100,400],
                click_action:process.env.VUE_APP_TRIPTRADER_API_HOST,
                data: {url:process.env.VUE_APP_TRIPTRADER_API_HOST},
                actions:[{action:"click_open_url",title:"open"}]
            };
            if (this.serviceWorkerRegistation === null) {
              console.log("Serviceworker not set: " + JSON.stringify(response.data));
              return navigator.serviceWorker.ready // returns a Promise, the active SW registration
              .then(swreg => {
                console.log("Serviceworker ready, setting  ref: " +swreg);
                par.serviceWorkerRegistation = swreg;
                par.serviceWorkerRegistation.showNotification(notificationTitle, options);
              })
            } else {
              console.log("navigator.serviceWorker is set ");
              par.serviceWorkerRegistation.showNotification(notificationTitle, options);

            }
          }
          // check if there's a running trip
          localStorage.setItem('opentrip', '');
          if(response.data != null) {
            if(response.data.opentrip != null) {
              localStorage.setItem('opentrip', response.data.opentrip);
            }
          } 

          // update position


          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
  mounted: function () {
    //Initial Load
    var targ = this;

    this.$store.dispatch("auth/session").then(
      () => {
        // this.$router.push("/");
        console.log("user is logged in");
      },
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("user is NOT logged in");

      }
    );

    // check if Firebase is up
     console.log('Firebase cloud messaging object', this.messaging)



    // Run every 5 seconds
    var loadData = function(){
          targ.loadMessages();
          targ.mounted = true;
          setTimeout(loadData, 5000);
    };
    if(!this.mounted) {
      loadData();
    }

    // var par = this;
    // this.$watchLocation()
    // .then(coordinates => {
    //   console.log("Watch coordinates: " + JSON.stringify(coordinates));
    //   localStorage.setItem('curloc', JSON.stringify(coordinates));
    // });

    window.swRegistration = null;
    function sendNotification(title,body) {
      const options = {
        body: body,
        icon: require('./assets/triptrader-logo.png'),
      };
      window.swRegistration.showNotification("sendNotification:" + title, options);
    }  

    function displayNotification() {
      if (window.Notification && Notification.permission === 'granted') {
        // notification();
      }
      else if (window.Notification && Notification.permission !== 'denied') {
        Notification.requestPermission(status => {
          if (status === 'granted') {
            sendNotification('Notificaties actief', 'U heeft toestemming gegeven om notificaties te ontvangen via de TripTrader App. U kunt dit weer uitschakelen via de instellingen van de browser.');
          } else {
            alert('You denied or dismissed permissions to notifications.');
          }
        });
      } else {
        // If the user refuses to get notified
        alert(
          'You denied permissions to notifications. Please go to your browser or phone setting to allow notifications.'
        );
      }
    }
    displayNotification();



  }
}
</script>


<style>
@import './assets/main.css';
</style>
