import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_TRIPTRADER_API_HOST;
class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        console.log("response: " + JSON.stringify(response.data));
        if (response.data.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data.data));
          if(response.data.data.opentrip != null) {

            localStorage.setItem('opentrip', response.data.data.opentrip);
          } else {
            localStorage.setItem('opentrip', '');

          }
        }
        return response.data;
      });
  }
  session() {
    return axios.get(API_URL + '/auth/auth',{ headers: authHeader() }).then(response => {
      // console.log("auth response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("AuthService: Response has NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
        localStorage.removeItem('user');
      } 
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem('user');
  }
  register(user) {
    return axios.post(API_URL + 'register', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
  activate(user) {
    return axios.post(API_URL + 'activate', {
      key: user.key,
      email: user.email
    });
  }
}
export default new AuthService();