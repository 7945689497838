import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();


import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App);
app.use(VueGoogleMaps, {
    load: {
        // key: "AIzaSyCrclbKLrlH-1ayTgx_IlEjfJpZiFaL1m0",
        key: process.env.VUE_APP_TRIPTRADER_GOOGLE_API_KEY,
        
        libraries: "places",
        useBetaRenderer: false,
        enableHighAccuracy: true
    },
});
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store);

app.mixin({
  
  methods: {
      loggedIn() {
        this.$store.dispatch("auth/session").then(
          (response) => {
            // this.$router.push("/");
            console.log("Mixin: user is logged in: " + JSON.stringify(response));
            var status = true;
            if(response.status == "failed") {
              status = false;
            } 
            return status;
          },
          (error) => {
            this.loading = false;
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            console.log("MIXIN: user session could not be loaded");
          }
        );
      },
      loggedinUser() {
        var lu = JSON.parse(localStorage.getItem('user'));
        var u = lu.userid;
        console.log("loggedinUser: " + u);
        return u;
      },
      loggedinUserName() {
        var lu = JSON.parse(localStorage.getItem('user'));
        var u = lu.username;
        console.log("loggedinUserName: " +u);
        return u;
      },
      zeroPad: function (num, places) {
        let r =  String(num).padStart(places, '0');
        return r;
      },
      formatCurrency: function (cur) {
          let num = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format((cur/100));
          return num;
      },
      formatDateTime: function (datetime) {
        var final = datetime.replace(/-/g, '/');
        var date = new Date(final);      
        var dstring = this.zeroPad(date.getDate(),2) + "-" + this.zeroPad(date.getMonth()+1, 2) + "-" + date.getFullYear() + " " + this.zeroPad(date.getHours(),2) + ":"+ this.zeroPad(date.getMinutes(), 2);
        return dstring;
      },
      statusFriendly: function (status) {
        var statusList = Array();
        statusList[1] = "Aangemaakt"; 
        statusList[2] = "Aangeboden"; 
        statusList[3] = "Aangenomen"; 
        statusList[4] = "Bezig"; 
        statusList[5] = "Gereden"; 
        statusList[6] = "Geannuleerd"; 
        statusList[7] = "Klaar"; 
        let st = statusList[status];
        return st;
      },
      openTab(tabname) {
        var i, tabcontent, tablinks;
        // Get all elements with class="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        // Get all elements with class="tablinks" and remove the class "active"
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        // Show the current tab, and add an "active" class to the button that opened the tab
        document.getElementById(tabname).style.display = "block";
        document.getElementById("tab-" + tabname).className += " active";
      },
      currentDateTime: function (offset) {
          var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
          var localISOString = new Date(Date.now() - tzoffset + offset)
            .toISOString()
            .slice(0, -1);
          console.log("localISOString::" + localISOString +  " + offset: " +offset );
          return localISOString;
      },
      currentLocation: function() {
        return JSON.parse(localStorage.getItem('curloc'));
      }
  },
  computed: {

  },
})


import Vue3Geolocation from 'vue3-geolocation';
app.use(Vue3Geolocation)


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBiL9ctJIabdYGsHfTyK-x88nXKpqtWVH0",
  authDomain: "taxidatamessagepool.firebaseapp.com",
  databaseURL: "https://taxidatamessagepool.firebaseio.com",
  projectId: "taxidatamessagepool",
  storageBucket: "taxidatamessagepool.appspot.com",
  messagingSenderId: "270131137828",
  appId: "1:270131137828:web:0a68fe5d77a611400d37b1"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);

app.use(firebase)
app.use(FontAwesomeIcon).use(router).mount('#app')

app.config.globalProperties.$messaging = firebase




// export default firebase.messaging()
console.log("firebase:" + JSON.stringify(firebase))
