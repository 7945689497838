import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Dash',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Aanmelden',
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'Afmelden',
    component: () => import('../views/Auth/Logout.vue')
  },
  {
    path: '/register',
    name: 'Registreren',
    component: () => import('../views/Auth/Register.vue')
  },
  {
    path: '/activate',
    name: 'Activeren',
    component: () => import('../views/Auth/Activate.vue')
  },
  {
    path: '/settings',
    name: 'Instellingen',
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/my/profile',
    name: 'Profiel',
    component: () => import('../views/Users/Profile.vue')
  },
  {
    path: '/my/profile/edit',
    name: 'Profiel bewerken',
    component: () => import('../views/Users/EditProfile.vue')
  },
  {
    path: '/my/preferences',
    name: 'Voorkeuren',
    component: () => import('../views/Users/Preferences.vue')
  },  
  {
    path: '/trips/open',
    name: 'Pool',
    component: () => import('../views/Trips/OpenTrips.vue')
  },
  {
    path: '/trips/my',
    name: 'Mijn ritten',
    component: () => import('../views/Trips/MyTrips.vue')
  },
  {
    path: '/trips/new',
    name: 'Nieuwe rit',
    showback: 1,
    component: () => import('../views/Trips/NewTrip.vue')
  },
  {
    path: '/trips/view/:id?',
    name: 'Rit details',
    showback: 1,    
    component: () => import('../views/Trips/ViewTrip.vue')
  },
  {
    path: '/trips/edit/:id?',
    name: 'Rit aanpassen',
    showback: 1,    
    component: () => import('../views/Trips/EditTrip.vue')
  },
  {
    path: '/trips/archived/view/:id?',
    name: 'Archiefrit details',
    component: () => import('../views/Trips/ViewArchivedTrip.vue')
  },
  {
    path: '/trips/offer/:id?',
    name: 'Rit aanbieden',
    component: () => import('../views/Trips/OfferTrip.vue')
  },
  {
    path: '/trips/accept/:id?',
    name: 'Rit aannemen',
    component: () => import('../views/Trips/AcceptTrip.vue')
  },
  {
    path: '/trips/end/:id?',
    name: 'Rit afsluiten',
    component: () => import('../views/Trips/EndTrip.vue')
  },
  {
    path: '/finance',
    name: 'Geld',
    component: () => import('../views/Finance.vue')
  },
  {
    path: '/circles',
    name: 'Cirkels',
    component: () => import('../views/Circles/Circles.vue')
  },
  {
    path: '/circles/view/:id?',
    name: 'Cirkel details',
    component: () => import('../views/Circles/ViewCircle.vue')
  },
  {
    path: '/contacts',
    name: 'Contacten',
    component: () => import('../views/Contacts/Contacts.vue')
  },
  {
    path: '/contacts/view/:id?',
    name: 'Contact details',
    component: () => import('../views/Contacts/ViewContact.vue')
  },
  {
    path: '/contacts/edit/:id?',
    name: 'Contact bewerken',
    component: () => import('../views/Contacts/EditContact.vue')
  },
  {
    path: '/contacts/new',
    name: 'Nieuw contact',
    showback: 1,
    component: () => import('../views/Contacts/NewContact.vue')
  }
]

const router = createRouter({
  mode: "history",
  store:store,
  history: createWebHashHistory(),
  
  routes
})


router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register','/activate'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const openTrip = localStorage.getItem('opentrip');
  console.log("to: "  +to.path+ " | from: " + from);
  // trying to access a restricted page + not logged in
  console.log(">>> ROUTER: beforeEach : to=" + to.path);


  // redirect to login page
  if (authRequired ) {
    console.log(">>> ROUTER: authRequired" );
    if ( loggedIn == null || loggedIn == '') {
      // console.log("logged out: ");
      next('/login');
    } else {
      var np = '/trips/view/' + openTrip;
      console.log(">>> ROUTER: username: " + JSON.parse(loggedIn) + "| openTrip=" + openTrip + " | np=" + np + " to.path=" + to.path);
      if(openTrip > 0 && to.path != np ) {
        next(np);
        console.log(">>> ROUTER: should reroute to open trip" );
      } else {
        next();
      }
        
    }
  } else {
    console.log(">>> ROUTER: auth NOT Required" );
    // console.log("logged in, next route: ");
    next();
  }
});


export default router
