import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.VUE_APP_TRIPTRADER_API_HOST;
class MessageService {
  getMyMessages() {
    return axios.get(API_URL + 'my/messages',{ headers: authHeader() }).then(response => {
      // console.log("getUserMessages response: " + JSON.stringify(response.data));
      if(response.data.status == "failed") {
        console.log("Response has  NO token:" );
        // localStorage.setItem('user', null);
        // localStorage.removeItem('user');
      }
      return response.data;
    });
  }
 
}
export default new MessageService();